/** @jsx jsx */
import { Box, Flex, Link, useColorMode, jsx } from 'theme-ui';

const Footer = () => {
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`);
  };

  return (
    <Box as="footer" variant="footer">
      <button
        sx={{
          variant: `buttons.toggle`,
          fontWeight: `semibold`,
          display: `block`,
          mx: `auto`,
          mb: 3,
        }}
        onClick={toggleColorMode}
        type="button"
        aria-label="Toggle dark mode"
      >
        {isDark ? `Light` : `Dark`}
      </button>
      <Flex
        sx={{
          justifyContent: `center`,
          alignItems: `center`,
          mt: 3,
          color: `text`,
          fontWeight: `semibold`,
          a: { color: `text` },
        }}
      >
        Copyright &copy; {new Date().getFullYear()} LER0ever. All rights
        reserved.
      </Flex>
      <br />
      Theme by LekoArts, Hosted on Netlify.
      <br />
      <Link
        aria-label="Link to the MIIT Beian webpage"
        sx={{ ml: 2 }}
        href="https://beian.miit.gov.cn/"
      >
        苏ICP备2021027080号-1
      </Link>
    </Box>
  );
};

export default Footer;
