import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`关于`}</h2>
    <p>{`我们从事混合现实领域的探索: 动作捕捉(Motion Capture)、多传感器室内定位、虚拟拍摄(Virtual Production)、视觉特效VFX，以及各子领域融合而出的产品。`}</p>
    <blockquote>
      <p parentName="blockquote">{`现实和虚幻的边界`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      