import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`相关RXXL实验室`}</h2>
    <ProjectCard title="RXRL | 扩展现实实验室" link="https://rxrl.rongyi.io" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Rong's <b>Extended Reality</b> Lab
    </ProjectCard>
    <ProjectCard title="RCIL | 计算智能实验室" link="https://rcil.rongyi.io" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Rong's <b>Computational Intelligence</b> Lab
    </ProjectCard>
    <ProjectCard title="RPLT Lab | 编程语言&工具实验室" link="https://rplt.rongyi.io" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Rong's <b>Programming Languages & Tools</b> Lab
    </ProjectCard>
    <ProjectCard title="RPSL | 推进系统实验室" link="https://rpsl.rongyi.io" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Rong's <b>Propulsion Systems</b> Lab
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      